<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button
                        @click="$root.$children[0].openModal('channel-modal', {id: 0}, afterSave, {width: '800px'})"
                        variant="primary"
                        class="mb-2"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.button.create') }}
                    </b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-table-simple striped>
                        <b-thead head-variant="info">
                            <b-th>{{ $t('multichannel.settings.label.id') }}</b-th>
                            <b-th>{{ $t('multichannel.settings.label.api_type') }}</b-th>
                            <b-th>{{ $t('multichannel.settings.label.host') }}</b-th>
                            <b-th>{{ $t('multichannel.settings.label.is_enable') }}</b-th>
                            <b-th>{{ $t('multichannel.settings.label.status') }}</b-th>
                            <b-th>{{ $t('multichannel.settings.label.actions') }}</b-th>
                        </b-thead>
                        <tbody>
                        <b-tr v-for="channel in channels" :key="channel.id">
                            <b-td>{{ channel.id }}</b-td>
                            <b-td>{{ channel.api_type }}</b-td>
                            <b-td>{{ channel.source_host }}</b-td>
                            <b-td>
                                <CBadge :color="channel.is_enable ? 'success' : 'danger' ">
                                    {{ channel.is_enable ? $t('common.button.yes') : $t('common.button.no') }}
                                </CBadge>
                            </b-td>
                            <b-td>{{ channel.connect_status }}</b-td>
                            <b-td>
                                <b-dropdown class="mb-1"
                                            left
                                            variant="primary"
                                            size="sm"
                                >
                                    <template #button-content>
                                        {{ $t('common.label.actions') }}
                                    </template>

                                    <b-dropdown-item v-if="channel.api_type != 'erika'"
                                                     @click="$root.$children[0].openModal('channel-modal', {id: channel.id}, afterSave, {width: '800px'})"
                                    >
                                        <font-awesome-icon class="mr-2" icon="edit"/>
                                        {{ $t('common.title.edit') }}
                                    </b-dropdown-item>

                                    <b-dropdown-item
                                        @click="$root.$children[0].openModal('channel-settings-modal', {id: channel.id})"
                                    >
                                        {{ $t('common.title.configure') }}
                                    </b-dropdown-item>

                                    <b-dropdown-item v-if="channel.api_type != 'erika'"
                                                     @click="syncChannel(channel.api_type)"
                                    >
                                        {{ $t('common.button.sync_orders') }}
                                    </b-dropdown-item>

                                    <b-dropdown-item v-if="channel.api_type != 'erika'"
                                                     @click="testChannel(channel.id)"
                                    >
                                        {{ $t('common.button.test') }}
                                    </b-dropdown-item>

                                    <b-dropdown-item
                                        @click="deleteChannel(channel.id)"
                                    >
                                        <font-awesome-icon class="mr-2" icon="trash"/>
                                        {{ $t('common.title.delete') }}
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-td>
                        </b-tr>
                        </tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'Settings-channels',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            channels: [],
        }
    },
    methods: {
        ...mapGetters('Channel', ['getSources']),
        shown() {
            const channels = this.$store.dispatch('Channel/fetchSources')

            Promise.all([channels])
                .then(() => {
                    this.channels = this.getSources()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        syncChannel(name) {
            this.$root.$children[0].openModal('channel-sync-modal', {source: name}, null, {width: '800px'})
        },
        deleteChannel(id) {
            this.$removeConfirm('Channel/deleteSource', id, this.afterSave)
        },
        afterSave() {
            this.shown()
            this.$root.afterSettingsUpdate()
        },
        testChannel(id) {
            this.loading = true
            this.$store.dispatch('Channel/testChannel', id)
                .then((response) => {
                    if (response.data.status == 'ok') {
                        this.$root.showMessage(
                            this.$t('common.form.title.success'),
                            this.$t('common.form.title.success') + response.data.message,
                            'success'
                        )
                    } else {
                        this.$root.showMessage(
                            this.$t('common.form.title.error'),
                            response.data.status + ': ' + response.data.message,
                            'danger'
                        )
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.afterSave();
                })
        }
    },
    mounted() {
        this.shown()
    }
}
</script>